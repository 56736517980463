/* ====== Background ===== */
.bg-primary {
  background-color: #ffedb3;
}
.bg-white {
  background-color: #fff;
}

.bg-primary-main {
  background-color: #fbc90b;
}

.bg-grey-head {
  background-color: #f8f7f6;
}

.bg-grey {
  background-color: #e1e0de;
}

.bg-program-builder {
  background-color: #fffcf0;
}

.bg-trans {
  background-color: transparent;
}
/* ====== font-color ===== */

.text {
  color: #8c8673;
}

.text-primary {
  color: #fbc90b;
}

.text-black {
  color: #2b2923;
}

.text-black-dark {
  color: #0a0a09 !important;
}

.text-grey {
  color: #8c8673;
}

/* ====== object-fit ===== */
.object-fit-contain {
  object-fit: contain;
}

/* ====== hover ===== */

.hover-text-white:hover {
  color: white;
}

/* ====== text decoration ===== */

.text-decoration-underline {
  text-decoration: underline;
}

/* ====== font-size ===== */

.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-28 {
  font-size: 28px;
}

/* ====== font-weight ===== */

.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700;
}

/* ====== Margin ===== */

.m-20 {
  margin: 20px;
}
.mt-1 {
  margin-top: 10px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}

.mr-1 {
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-3 {
  margin-left: 30px;
  margin-right: 30px;
}
.mx-4 {
  margin-left: 40px;
  margin-right: 40px;
}
.mx-5 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-auto {
  margin-left: auto;
  margin-left: auto;
}

/* ====== padding ===== */

.p-8px {
  padding: 8px;
}

.p-30 {
  padding: 30px;
}
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0 !important;
}
.p-1 {
  padding: 10px;
}
.p-16 {
  padding: 16px;
}

.p-13 {
  padding: 13px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.p-4 {
  padding: 40px;
}
.p-5 {
  padding: 50px;
}

.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}

.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pl-5 {
  padding-left: 50px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-3 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-4 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-5 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* ====== text-align ===== */

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-end {
  text-align: end;
}

/* ====== list-style ===== */

.list-style-auto {
  list-style: auto;
}

/* ====== border-radius ===== */

.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-50 {
  border-radius: 50px;
}

/* ====== border ===== */

.border-primary {
  border: 1px solid #fbc90b;
}

.border-grey {
  border: 1px solid #e2e1dc;
}

.border-dark {
  border: 1px solid #e1e0de;
}

/* ====== box-shadow ===== */

.box-shadow-layout {
  box-shadow: 1px 0px 50px rgba(205, 202, 194, 0.25) !important;
}

.box-shadow-2 {
  box-shadow: 0px 0px 8px 4px rgba(85, 85, 85, 0.06);
}

/* ====== width ===== */

.width-150 {
  width: 150px !important;
}
.width-180 {
  width: 180px !important;
}
.width-250 {
  width: 250px !important;
}
.width-200 {
  width: 200px !important;
}
.w-100 {
  width: 100% !important;
}

@media (max-width: 500px) {
  .width-250 {
    width: 100% !important;
  }
}

/* ====== height ===== */

.h-100 {
  height: 100vh !important;
}

.h-54 {
  height: 54px;
}

.h-80 {
  height: 80px;
}

/* ====== flex ===== */

.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: end;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-baseline {
  align-items: baseline;
}
.justify-content-between {
  justify-content: space-between !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-direction-column {
  flex-direction: column;
}

@media (max-width: 991px) {
  .justify-content-md-left {
    justify-content: left !important;
  }
}

/* ====== Gap ===== */
.g-3 {
  gap: 30px;
}

.g-16 {
  gap: 16px;
}

.gap-10 {
  gap: 10px;
}

/* ====== Display ===== */
.d-unset {
  display: unset !important;
}
.d-inline {
  display: inline;
}

/* ====== line-height ===== */

.line-height-22 {
  line-height: 22px;
}
.line-height-26 {
  line-height: 26px !important;
}

.line-height-34 {
  line-height: 34px !important;
}

/* ====== cursor ===== */

.cursor-pointer {
  cursor: pointer;
}

/* ====== work-break ===== */
.word-break-all {
  word-break: break-all;
}

/* ====== overFlow ===== */
.overflow-auto {
  overflow: auto !important;
}

/* ====== Remove from small screen ===== */

@media (max-width: 767px) {
  .d-sm-none {
    display: none;
  }
  .text-xs-left {
    text-align: left !important;
  }
}
