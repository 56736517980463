html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
}

.headerProfileUser {
  height: 40px;
  width: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  background-color: white;
  height: 76px;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
  z-index: 9;
  position: sticky;
  top: 0;
}

// Login
.title {
  text-align: center;
  font-size: 16px;
  color: #85827a;
}
.btn {
  width: 100%;
  margin-top: 15px;
}

.login_main_div {
  height: 90vh;
  display: flex;
  justify-content: center;
  .login_wrapper {
    width: 500px;
    max-width: 90%;
    margin: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 30px;
    border-radius: 5px;
    .ant-form-item-label {
      .ant-form-item-required {
        font-size: 18px;
        line-height: 26px;
        color: black;
      }
    }
  }
}

// Search Box
.ant-input-affix-wrapper {
  padding: 10px;
}

//search section
.search-section {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .ant-input-affix-wrapper {
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 16px;
  }
  &__btn-group {
    margin-left: 20px;
  }
  &__select {
    width: 200px;
    max-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .search-section {
    div {
      flex-direction: column;
    }
    &__btn-group {
      width: 100%;
      flex-direction: row !important;
      justify-content: start !important;
      flex-wrap: wrap;
      margin: 0;
      button {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
    &__select {
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .main-search div {
    flex-direction: column;
  }
  .btnCount-3 {
    width: 100% !important;
    flex-direction: row !important;
    justify-content: start !important;
    flex-wrap: wrap;
    margin: 0;
    button {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

// Ant Menu
.ant-menu-vertical {
  padding: 12px 16px 0 16px;
}

.ant-select-selector {
  border-radius: 8px !important;
  height: 51px !important;
  display: flex !important;
  align-items: center !important;
}

.sidebar-menu {
  height: 50px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ant-menu-title-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

// Pagination And Table

.ant-pagination {
  display: flex;
}

.ant-pagination-item {
  border-radius: 8px;
}

.ant-pagination-prev {
  border: none !important;
  color: black;
  display: flex;
  align-items: center;
  margin: 0;
}

.ant-pagination-next {
  display: flex;
  align-items: center;
}

.ant-pagination-options {
  display: none;
}

.ant-table-cell {
  font-size: 18px !important;
  line-height: 26px !important;
  color: #000000 !important;
}

.ant-table-thead {
  .ant-table-cell {
    font-size: 18px !important;
    line-height: 26px !important;
    color: #8c8673 !important;
    font-weight: 600;
  }
}

.table-cover-img {
  width: 90px;
  height: 80px;
  border-radius: 8px !important;
  object-fit: contain;
}

// layout
.ant-layout-content {
  padding: 30px;
  background: #f7f7f7 !important;
  overflow: auto;
}

// Button Component

.btn-component {
  border-radius: 8px;
  height: 50px !important;
  margin-bottom: 0 !important;
  span {
    font-size: 16px;
    line-height: 26px;
    color: #2b2923;
  }
}

.btn-inside-header {
  width: 180px !important;
}

// User Form
.add-edit-form {
  max-width: 100%;
  margin: auto;
  padding: 30px !important;
  position: relative;
  margin-top: 20px !important;
  form {
    margin-top: 30px !important;
    .ant-form-item {
      margin-bottom: 25px !important;
      .ant-input-affix-wrapper {
        padding: 10px;
        font-size: 16px !important;
      }
    }
    .ant-form-item-label {
      width: 28%;
      min-width: 150px;
      text-align: start;
      margin-right: 15px;
      label {
        font-size: 16px !important;
        &::after {
          content: none;
        }
      }
    }
  }
}
.form-btn-group {
  display: flex;
  justify-content: center;
  gap: 30px;
  border-radius: 8px;
  button {
    width: 150px !important;
  }
  .btn-cancel {
    background-color: transparent !important;
  }
}

.ant-form-item-label {
  display: flex;
  align-items: center;
}

.ant-form-item-required {
  font-size: 18px !important;
  line-height: 26px !important;
  color: #000000;
}

// Cards
.card-sections {
  padding: 16px;
  height: 300px;
  border-radius: 8px;
  .card-section-img {
    height: 164px;
    border-radius: 4px;
    object-fit: contain;
  }
  .ant-card-body {
    padding: 0;
  }
}

.select-inside-header {
  width: 90% !important;
  margin-left: auto;
}

.vid-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

// model
.ant-modal-header {
  text-align: center;
}
.ant-modal-title {
  font-size: 18px;
}
.ant-modal-footer {
  text-align: center !important;
}

.ant-drawer-content-wrapper {
  width: 697px !important;

  @media (max-width: 696px) {
    width: 100% !important;
  }
}

.padding-head {
  padding: 12px 30px;
}

.thumb-img {
  display: flex !important;
  gap: 30px !important;
  align-items: center !important;
}
.btn-thumb {
  height: 50px;
  width: 150px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.ant-upload-list {
  width: 100% !important;
  .ant-upload-list-item {
    border-radius: 8px !important;
  }
}

// Thumb image upload button
.thumb-img {
  .ant-form-item-control-input-content {
    span {
      color: #0a0a09;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// Pagination
.ant-pagination-item-active a {
  color: black !important;
  background: var(--ant-primary-color) !important;
  border-radius: 6px !important;
}

// table
table {
  thead {
    tr {
      th {
        background-color: #fffcf0 !important;
      }
    }
  }
}

// Workout builder
.fix-width {
  min-width: 175px;
  overflow: auto;
}
.scroll {
  min-width: 620px;
}

// Add Week Drawer

.add-week-drawer > .ant-drawer-content-wrapper {
  width: 1160px !important;
}

.add-week-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  background-color: #f7f7f7;
}

.ant-modal-footer > .ant-btn-primary {
  color: black;
}

// Ant Upload
.ant-upload-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
