@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

// utility
@import 'utility';

@import 'common';
